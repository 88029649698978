<template>
	<v-form>
		<v-combobox
			:label="$t('collaboration.fields.user_name')"
			persistent-hint
			:hint="$t('misc.combobox_hint', { item: 'colleague' })"
			clearable
			:disabled="progress"
			:items="items"
			v-model="user"
		>
		</v-combobox>
		<v-text-field
			v-model="email"
			:label="$t('misc.email')"
			:disabled="progress"
			:rules="[(v) => mwutils.validateEmail(v) || 'Invalid Email']"
			v-if="isNew"
		></v-text-field>
		<v-checkbox
			v-if="isNew && !invalidForm"
			label="Are you sure you want to add a new colleague"
			v-model="sure"
		></v-checkbox>
		<v-progress-linear
			indeterminate
			color="primary"
			v-if="progress"
		></v-progress-linear>
	</v-form>
</template>

<script>
import firebase from "firebase";
export default {
	name: "AddColleague",
	props: {
		value: { type: String }, // the id of the user
	},
	data: () => {
		return {
			user: null,
			sure: false,
			email: null,
			progress: false,
		};
	},
	computed: {
		users() {
			return this.$store.getters["users/respondents"];
		},
		items() {
			return this.mwutils.itemsArray(this.users, "displayName");
		},
		invalidForm() {
			return (
				this.isNew &&
				(!this.user || !this.mwutils.validateEmail(this.email))
			);
		},
		isNew() {
			return typeof this.user == "string" && !this.users[this.user];
		},
	},
	methods: {
		async addNewUser() {
			var functions = firebase.app().functions("europe-west1");
			var addUser = functions.httpsCallable("addColleague");
			var user = {
				organisation: this.$store.state.organisation.data.id,
				displayName: this.user,
				email: this.email,
			};
			return await addUser(user);
		},
		parseEmail(a) {
			return a.toLowerCase().trim();
		},
	},
	watch: {
		sure(value){
			if( value ){
				this.progress = true;
				this.addNewUser().then((res) => {
					this.progress = false;
					this.$store.dispatch("users/fetchById", res.data.id );
					this.$emit("input", res.data.id);
				});
			}
		}, 
		user(){
			if( !this.isNew ){
				this.$emit("input", this.user.value);
			}
		},
		email(value) {
			this.email = this.parseEmail(value);
		},
	},
	created() {
		if (this.value) {
			this.user = this.users[this.value];
		}
	},
};
</script>
