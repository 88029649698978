<template>
    <mw-dialog
        :value="value"
        @input="$emit('cancel')"
        small
        submitBtn
        :title="$t('misc.are_you_sure')"
        @submit="$emit('input')"
    >
        <template v-slot:dialog-body>
            <p>{{  text }}</p>
        </template>
    </mw-dialog>


</template>

<script>
    import MwDialog from "@c/ui/MwDialog"
    export default {
        name: "AreYouSure", 
        props: {
            value: { },
            text: {}
        },
        components: {
            MwDialog
        }
    }
</script>